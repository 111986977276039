import jQuery from 'jquery';
import 'components-jqueryui/jquery-ui';
import 'jquery-ui-touch-punch';

/**
 * Ensure jQuery is also available as `window.jQuery`.
 * Mix/Webpack does have an alias, but in some instances (read: tinyMCE),
 * this is not loaded properly.
 * This is because it checks for the global variable, which breaks the Mix alias.
 */
const dynamicWindow = (typeof global !== 'undefined' ? global : window);
dynamicWindow.jQuery = jQuery;
dynamicWindow.$ = $;
